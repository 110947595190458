exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-android-app-development-services-js": () => import("./../../../src/pages/android-app-development-services.js" /* webpackChunkName: "component---src-pages-android-app-development-services-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogsearch-js": () => import("./../../../src/pages/blogsearch.js" /* webpackChunkName: "component---src-pages-blogsearch-js" */),
  "component---src-pages-codeigniter-web-development-services-js": () => import("./../../../src/pages/codeigniter-web-development-services.js" /* webpackChunkName: "component---src-pages-codeigniter-web-development-services-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-flutter-app-development-services-js": () => import("./../../../src/pages/flutter-app-development-services.js" /* webpackChunkName: "component---src-pages-flutter-app-development-services-js" */),
  "component---src-pages-graphics-design-services-js": () => import("./../../../src/pages/graphics-design-services.js" /* webpackChunkName: "component---src-pages-graphics-design-services-js" */),
  "component---src-pages-hire-dedicated-developers-js": () => import("./../../../src/pages/hire-dedicated-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-app-development-services-js": () => import("./../../../src/pages/ios-app-development-services.js" /* webpackChunkName: "component---src-pages-ios-app-development-services-js" */),
  "component---src-pages-laravel-web-development-services-js": () => import("./../../../src/pages/laravel-web-development-services.js" /* webpackChunkName: "component---src-pages-laravel-web-development-services-js" */),
  "component---src-pages-magento-web-development-services-js": () => import("./../../../src/pages/magento-web-development-services.js" /* webpackChunkName: "component---src-pages-magento-web-development-services-js" */),
  "component---src-pages-php-web-development-services-js": () => import("./../../../src/pages/php-web-development-services.js" /* webpackChunkName: "component---src-pages-php-web-development-services-js" */),
  "component---src-pages-quality-analysis-js": () => import("./../../../src/pages/quality-analysis.js" /* webpackChunkName: "component---src-pages-quality-analysis-js" */),
  "component---src-pages-search-blog-js": () => import("./../../../src/pages/search-blog.js" /* webpackChunkName: "component---src-pages-search-blog-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shopify-web-development-services-js": () => import("./../../../src/pages/shopify-web-development-services.js" /* webpackChunkName: "component---src-pages-shopify-web-development-services-js" */),
  "component---src-pages-start-your-next-project-js": () => import("./../../../src/pages/start-your-next-project.js" /* webpackChunkName: "component---src-pages-start-your-next-project-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wordpress-web-development-services-js": () => import("./../../../src/pages/wordpress-web-development-services.js" /* webpackChunkName: "component---src-pages-wordpress-web-development-services-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

